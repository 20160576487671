<template>
  <div>
      <v-container>
        <v-alert  border="left"
            class="border"
            colored-border
            color="#00919B"
            icon="mdi-information-outline"
            elevation="0">
              <p class="h6 black--text mb-1">Do you own a company?</p>
              <p> Is your company not on the list? </p>
              <v-btn text color="#0A009B" @click="create" >
                <v-icon class="mr-2">mdi-plus</v-icon>
                 Add a company </v-btn>
        </v-alert>
       <div v-if="arrays.user_companies && arrays.user_companies.length > 0">
            <p class="text-caption text-uppercase mb-2 text-secondary" v-if="ownerCompany && ownerCompany.length > 0" >Owned Companies </p>
            <!-- <p class="text-caption text-uppercase mb-2 text-secondary" v-if="status.current_company && status.current_company.role == 'SA'" >Owned Companies </p> -->
            <v-row >
              <v-col cols="12" md="3" lg="3" v-if="status.current_company && status.current_company.role == 'SA'">
                <v-card outlined min-height="300">
                  <v-card-title class="text-wrap mb-0"> {{ status.current_company.company.name }} </v-card-title>
                  <div class="w-100 rounded" >
                    <v-img v-if="status.current_company.company.logo"
                        max-height="105"
                        min-height="105"
                        class="white--text"
                        :src="status.current_company.company.logo | getBaseURL"
                        @error="checkLoaded('current', null)"
        
                       
                      >
                    </v-img>
                    <v-img v-else
                        max-height="105"
                        class="white--text"
                        src="@/assets/company.svg"
                      >
                    </v-img>
                  </div>
                  <v-card-text>
                  <small class="text-dark d-block"> 
                    <v-icon size="18" class="mr-2"> mdi-email-outline </v-icon>
                    {{ status.current_company.company.company_email }}
                  </small>
                  <small class="text-dark d-block"> 
                    <v-icon size="18" class="mr-2"> mdi-phone-outline </v-icon>
                    {{ status.current_company.company.contact_number ? status.current_company.company.contact_number : '' | telephone }}
                  </small>
                  </v-card-text>
                  <v-card-actions class="border-top" v-if="status.admin">
                    <div class="d-block text-right w-100">
                      <!-- <v-btn text color="#9B0A00"  @click="remove">Delete</v-btn> -->
                      <v-btn text color="#0A009B" @click="manage">Manage</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12"
                md="3"
                lg="3"
      
                v-for="user_company in ownerCompany"
                :key="user_company.id">
                
                  <v-card outlined min-height="300" v-if="user_company.role == 'SA' ">
                    <v-card-title class="text-wrap"> {{ user_company.company.name }} </v-card-title>
                    <div class="w-100 rounded" >
                      <v-img
                          v-if="user_company.company.logo"
                          max-height="105"
                          height="105"
                          class="white--text"
                          :src="user_company.company.logo | getBaseURL"
                          @error="checkLoaded('list', user_company.id)"
                        >
                      </v-img>
                      <v-img
                          v-else
                          max-height="105"
                          height="105"
                          class="white--text"
                          src="@/assets/company.svg"
                        >
                      </v-img>
                    </div>
                    <v-card-text>
                    <small class="text-dark d-block"> 
                      <v-icon size="18" class="mr-2"> mdi-email-outline </v-icon>
                      {{ user_company.company.company_email ? user_company.company.company_email : 'N/A' }}
                    </small>
                    <small class="text-dark d-block"> 
                      <v-icon size="18" class="mr-2"> mdi-phone-outline </v-icon>
                      {{ user_company.company.contact_number | telephone}}
                    </small>
                    </v-card-text>
                    <v-card-actions class="border-top py-0">
                      <small class="mb-0 caption d-block mt-2 text-secondary text-center"> To manage this company please change your current company selected </small> 
                    </v-card-actions>
                  </v-card>
              </v-col>
            </v-row>
  
       </div>
        <div v-if="affiliatedCompany && affiliatedCompany.length > 0" class="mt-4">
            <p class="text-caption text-uppercase mb-2 text-secondary">Affiliated Companies </p>
            <v-row >
              <v-col
                cols="12"
                md="3"
                lg="3"
                v-for="user_company in affiliatedCompany"
                :key="user_company.id">
          
                  <v-card outlined min-height="300" v-if="user_company.role != 'SA' ">
                    <v-card-title> <p class="mb-0 text-wrap"> {{ user_company.company.name }} </p> </v-card-title>
                    <div class="w-100 rounded" >
                      <v-img
                        v-if="user_company.company.logo"
                          max-height="150"
                          height="150"
                          class="white--text"
                          :src="user_company.company.name | getBaseURL"
                        >
                      </v-img>
                      <v-img
                      v-else
                          max-height="150"
                          height="150"
                          class="white--text"
                          src="@/assets/company.svg"
                        >
                      </v-img>
                    </div>
                    <v-card-text>
                    <small class="text-dark d-block"> 
                      <v-icon size="18" class="mr-2"> mdi-email-outline </v-icon>
                      {{ user_company.company.company_email ? user_company.company.company_email : 'N/A' }}
                    </small>
                    <small class="text-dark d-block"> 
                      <v-icon size="18" class="mr-2"> mdi-phone-outline </v-icon>
                      {{ user_company.company.contact_number | telephone}}
                    </small>
                    </v-card-text>
                  </v-card>
              </v-col>
            </v-row>
            <pagination v-bind:meta="arrays.meta" @page="page"> </pagination>
        </div >
        <div class="w-100 text-center py-10" v-if="loadings.affiliated_companies"> 
          <v-progress-circular indeterminate color="grey" size="64"></v-progress-circular>
        </div>
        
      </v-container>
      <create v-bind:myInfo="arrays.me" v-bind:dialog="dialogs.create" v-model="dialogs.create" @refresh="refresh"> </create>
      <remove v-bind:admin="status.admin" v-bind:company="status.current_company" v-model="dialogs.delete" v-bind:dialog="dialogs.delete" @refresh="refresh"> </remove>
    </div>
</template>

<script>
import create from '@/components/Company/create'
import remove from '@/components/Company/delete'
import pagination from '@/components/Pagination'

export default {
  components: {
    create,
    remove,
    pagination
  },
  props: {
    myInfo: Object
  },
  data: () => ({
    arrays : {
      me : [],
      user_companies : null,
      meta: {},
    },
    status : {
      admin : false,
      current_company : null,
      found : true
    },
    loaded: null,
    dialogs: {
      create: false,
      delete : false,
    },
    loadings: {
      current_company : true,
      affiliated_companies : true
    }    
  }),
  created() {
    if(this.myInfo)
    {
      this.setUserInfo()
    }
 
  },
  watch : {
    myInfo : function(val)
    {
      if(val)
      {
        this.setUserInfo()
      }
      
    }
  },
  computed: {
      ownerCompany()
      {
        if(this.arrays.user_companies && this.arrays.user_companies.length > 0)
        {
          return this.arrays.user_companies.filter( company => {
              return company.role == "SA" && company.company.id != this.status.current_company.company.id
          })
        }else{
          return []
        }
        
      },
      affiliatedCompany()
      {
        if(this.arrays.user_companies && this.arrays.user_companies.length > 0)
        {
          return this.arrays.user_companies.filter( company => {
            return company.role != "SA"
          } )
        }else{
          return []
        }
        
      }
  }, 
  methods: {
    checkLoaded( type , id)
    {
      
      if(type == 'current')
      {
        this.status.current_company.company.logo = null
      }

      if(type == 'list')
      {
        if(this.arrays.user_companies && this.arrays.user_companies.length > 0)
        {
          for(let i in this.arrays.user_companies)
          {
            if(!isNaN(i) )
            {
              if(this.arrays.user_companies[i].id == id )
              {
                this.arrays.user_companies[i].company.logo = null
              }
            }
          }
        }
      }

    },
    setUserInfo() {
      this.arrays.me = this.myInfo;
      this.arrays.me.default_user_company.role == 'SA' ? this.status.admin = true : this.status.admin = false
      this.getCompanies();
    },
    getCompanies(page) {
      
      let p = ''
      if(page){
        p = page
      }
      let url = '/getusercombyuserid/' + this.arrays.me.id + '&page=' + p
      this.axios
        .get(url)
        .then(({ data }) => {
          this.arrays.user_companies = data.companies
          this.status.current_company = this.arrays.me.default_user_company
          if(!p)
          {
            this.arrays.meta = data.meta
          }
        })
        .catch()
        .finally(() => {
          this.loadings.current_company = false
          this.loadings.affiliated_companies = false
        })
    },
    create()
    {
      this.$router.push({path : '/account/register-company'})
    },
    remove() {
      this.dialogs.delete = true
    },
    refresh()
    {
      this.loadings.affiliated_companies = true
      this.$emit('refresh', true)
    },
    manage()
    {
      this.$router.push({name: 'Manage-Company', params:{id:this.status.current_company.id}})
    },
    page(page)
    {
      this.arrays.user_companies = []
      this.loadings.affiliated_companies = true
      window.scroll(0,0)
      this.getCompanies(page)
    }
    
    // imageUpload()
    // {
    //   let file = event.target.files[0];

    // if (file.size > 1048770) {
    //   console.log('File is to Biiig');
    // }else{
    //   let reader = new FileReader();
    //   reader.onload = event => {
    //     this.form.logo = event.target.result;
    //   };
    //   reader.readAsDataURL(file);
    // }

    // }
  },
};
</script>
<style scoped>
#btn {
  text-transform: none;
  border: none;
}
.text-wrap{
  word-break: normal;
  word-wrap: break-word;
}
</style>