<template>
  <div>
    <v-snackbar v-model="snackbar.show" top right color="success">
      <v-icon class="mr-2">mdi-check</v-icon>
      <span> New Company Added! </span>
    </v-snackbar>
    <v-dialog v-model="show" persistent max-width="600px" >
      <v-card elevation="0">
        <v-toolbar color="#00439B" elevation="0">
            <v-toolbar-title class="text-white"> New Company </v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="create" ref="form" lazy-validation>
          <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Company Name*"
                    :rules="rules.name"
                    required
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company Email*"
                    required
                    :rules="rules.email"
                    v-model="form.company_email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company Address*"
                    required
                    :rules="rules.address"
                    v-model="form.address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company Contact Number (optional)"
                    v-model="form.contact_number"
                    @keyup="accept"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <timezone @select="select" v-bind:rules="rules.timezone"> </timezone>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    label="Company logo"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            <small  class="text-caption red--text">* indicates required field </small>
            <div v-if="arrays.errors.length > 0">
              <small v-for="error in arrays.errors" :key="error.id"  class="text-white py-1 px-2 border bg-danger rounded mr-1">
                {{ error }} </small>
            </div>
          </v-card-text>
          <v-card-actions class="border-top ">
            <div class="d-block text-right w-100">
              <v-btn text @click="close" class="text-secondary"> Close </v-btn>
              <v-btn type="submit" text color="#0A009B" :loading="loadings.btn_create" > Save </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
  
</template>
<script>
import timezone from '@/components/Timezone'
export default {
  components: {
    timezone
  },
  props: ['dialog', 'myInfo'],
  data()
  {
    return {
      arrays : {
        me: null,
        errors: [],
      },
      status : {
        me : false,
      },
      snackbar : {
        show : false
      },
      form: {
        name: null,
        logo: null,
        user_id: null,
        status: "Active",
        address: null,
        contact_number: null,
        company_email: null,
        parent_id: 2,
        timezone_id: null,
        date_created: null,
        billing_date : null,
      },
      loadings: {
        btn_create: false,
        overlay : false,
      },
      rules : {
        name: [
          (value) => !!value || "Name is required",
          (value) =>
            (value && value.length <= 50) || "Name must be less than 50 characters",
        ],
        email : [
          (value) => !!value || "Email is required",
          (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
        ],
        address : [
          (value) => !!value || "Address is required"
        ],
        image : [
          (value) =>
            !value || value.size < 2000000 || "Logo size should be less than 2 MB!",
        ],
      },
    }
  },
  computed:
  {
    show : {
      get()
      {
        if(this.myInfo)
        {
          this.populate()
        }
        return this.dialog;
      },
      set(value)
      {
         this.$emit("input", value);
      } 
    }
  },
  methods: {
    accept()
    {
      if(this.form.contact_number && this.form.contact_number.length > 0)
      {
        let x = this.form.contact_number.replace(/\D/g , '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        this.form.contact_number = !x[2] ? x[1] :  x[1] + ' ' + x[2] + ( x[3] ? ' ' +  x[3] : '');
      }
    },
    populate()
    {
      this.arrays.me = this.myInfo
      this.form.timezone_id = null
    },
    create()
    {

      if(this.$refs.form.validate() )
      {
        this.form.user_id = this.arrays.me.id;
        this.form.parent_id = this.arrays.me.default_user_company.company_id;
        this.form.billing_date = this.$options.filters.today()
        if(this.form.contact_number && this.form.contact_number.length > 0)
        {
           this.form.contact_number = this.form.contact_number.replace(/\s/g, '') 
        }
        this.form.user_company = {
          user_id : this.form.user_id,
          company_id: null,
          role : this.arrays.me.default_user_company.role,
          parent_id : this.arrays.me.default_user_company.company_id ,
          status: 'active',
          timezone_id : this.form.timezone_id,
          team_dashboard : this.arrays.me.default_user_company.company_id,
        }
        this.loadings.btn_create = true;
        this.axios
          .post("companies", this.form)
          .then(() => {
            this.show = false
            this.snackbar.show = true
            this.$emit('refresh', true)
            this.$refs.form.reset()
          })
          .catch( error => {
          
            if(error.response.status == 400)
            {
              this.arrays.errors = []
              let data = error.response.data.errors
              if(data.timezone_id)
              {
                this.arrays.errors.push('Please provide timezone')
              }
            }
          })
          .finally(() => (this.loadings.btn_create = false));
      }
     
    },
    close()
    {
      this.$refs.form.reset()
      this.arrays.errors = []
      this.form.timezone_id = null
      this.show = false
    },
    select(value) {
      this.form.timezone_id = value;
      this.arrays.errors = []
    },
  }
}
</script>